<template>
    <Transition :appear :mode :name="`wtc--${name}`" :style="durationStyle">
        <slot></slot>
    </Transition>
</template>

<script lang="ts">
export type animationType =
    | "fade"
    | "slide-up"
    | "slide-right"
    | "slide-down"
    | "slide-left"
    | "fade-only-in"
    | "slide-up-only-in"
    | "slide-right-only-in"
    | "slide-down-only-in"
    | "slide-left-only-in";
</script>

<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
    defineProps<{
        durationMs?: number;
        delayMs?: number;
        appear?: boolean;
        name?: animationType;
        mode?: "out-in" | "in-out";
    }>(),
    {
        name: "fade",
        durationMs: 200,
        delayMs: 0,
        mode: "out-in"
    }
);

const durationStyle = computed(
    () => `--wtc-transition-duration: ${props.durationMs}ms; --wtc-transition-delay: ${props.delayMs}ms;`
);
</script>

<style lang="scss">
// Transitions with leave + enter
.wtc--fade-enter-active,
.wtc--fade-leave-active {
    transition: opacity var(--wtc-transition-duration) ease-in-out;
    transition-delay: var(--wtc-transition-delay);
}

.wtc--fade-enter-from,
.wtc--fade-leave-to {
    opacity: 0;
}

.wtc--slide-up-enter-active,
.wtc--slide-up-leave-active {
    transition: all var(--wtc-transition-duration) ease-in-out;
    transition-delay: var(--wtc-transition-delay);
}
.wtc--slide-up-enter-from,
.wtc--slide-up-leave-to {
    transform: translateY(10px);
    opacity: 0;
}

.wtc--slide-right-enter-active,
.wtc--slide-right-leave-active {
    transition: all var(--wtc-transition-duration) ease-in-out;
    transition-delay: var(--wtc-transition-delay);
}
.wtc--slide-right-enter-from,
.wtc--slide-right-leave-to {
    transform: translateX(-20px);
    opacity: 0;
}

.wtc--slide-down-enter-active,
.wtc--slide-down-leave-active {
    transition: all var(--wtc-transition-duration) ease-in-out;
    transition-delay: var(--wtc-transition-delay);
}
.wtc--slide-down-enter-from,
.wtc--slide-down-leave-to {
    transform: translateY(-10px);
    opacity: 0;
}

.wtc--slide-left-enter-active,
.wtc--slide-left-leave-active {
    transition: all var(--wtc-transition-duration) ease-in-out;
    transition-delay: var(--wtc-transition-delay);
}
.wtc--slide-left-enter-from,
.wtc--slide-left-leave-to {
    transform: translateX(20px);
    opacity: 0;
}

// Transitions with enter only

.wtc--fade-only-in-enter-active {
    transition: opacity var(--wtc-transition-duration) ease-in-out;
    transition-delay: var(--wtc-transition-delay);
}
.wtc--fade-only-in-enter-from {
    opacity: 0;
}

.wtc--slide-up-only-in-enter-active {
    transition: all var(--wtc-transition-duration) ease-in-out;
    transition-delay: var(--wtc-transition-delay);
}
.wtc--slide-up-only-in-enter-from {
    transform: translateY(10px);
    opacity: 0;
}

.wtc--slide-right-only-in-enter-active {
    transition: all var(--wtc-transition-duration) ease-in-out;
    transition-delay: var(--wtc-transition-delay);
}
.wtc--slide-right-only-in-enter-from {
    transform: translateX(-20px);
    opacity: 0;
}

.wtc--slide-down-only-in-enter-active {
    transition: all var(--wtc-transition-duration) ease-in-out;
    transition-delay: var(--wtc-transition-delay);
}
.wtc--slide-down-only-in-enter-from {
    transform: translateY(-10px);
    opacity: 0;
}

.wtc--slide-left-only-in-enter-active {
    transition: all var(--wtc-transition-duration) ease-in-out;
    transition-delay: var(--wtc-transition-delay);
}
.wtc--slide-left-only-in-enter-from {
    transform: translateX(20px);
    opacity: 0;
}
</style>
