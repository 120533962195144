<template>
    <div class="p-3">
        <div
            class="theme--component-wrapper max-tablet:w-full bg-surface-secondary flex-1 rounded border border-black/10"
        >
            <div class="theme--component-header font-normal_bold px-3 py-2 text-sm">
                <span>{{ timingChannel.EventName }}</span>
                <span>{{ timingChannel.PhaseName }}</span>
                <span>{{ timingChannel.UnitName }}</span>
            </div>

            <div class="max-laptop:overflow-x-auto">
                <table ref="tableReference" class="w-full">
                    <thead>
                        <tr>
                            <th class="!sticky left-0 w-12">Lap</th>
                            <th v-for="startListItem in sortedStartList" :key="startListItem.AthleteId">
                                <div class="flex gap-1">
                                    <FalconFlag :nat-code="startListItem.Nationality"></FalconFlag>
                                    <WtcName class="text-white" :competitor-name="startListItem"></WtcName>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <WtcTransitionGroup appear name="slide" tag="tbody">
                        <tr v-for="split in reverseSplits" :key="split.Number">
                            <td class="bg-surface sticky left-0">{{ split.Number }}</td>
                            <td
                                v-for="startListItem in sortedStartList"
                                :key="startListItem.AthleteId"
                                class="comp-rank-result"
                            >
                                <div
                                    v-highlight="startListItem.Intermediate?.[split.Number - 1]?.Rank"
                                    class="flex flex-row gap-3.5"
                                >
                                    <span class="rank">{{ startListItem.Intermediate?.[split.Number - 1]?.Rank }}</span>
                                    <span class="result">{{
                                        startListItem.Intermediate?.[split.Number - 1]?.Result
                                    }}</span>
                                </div>
                            </td>
                        </tr>
                    </WtcTransitionGroup>
                </table>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { FalconFlag } from "@swisstiming/falcon-core";
import { ITimingChannel, useTimingChannelData, WtcName, WtcTransitionGroup } from "@swisstiming/webtec-kit";
import { createNativeDesktopMomentumScroller } from "@swisstiming/webtec-kit/src/utils/scrolling.utils";
import { computed, PropType, ref, toRef, watch } from "vue";

const props = defineProps({
    timingChannel: {
        type: Object as PropType<ITimingChannel>,
        default: (): ITimingChannel => {
            return {};
        }
    }
});

const tableReference = ref<HTMLElement>();
const unWatch = watch(tableReference, (domTableReference) => {
    createNativeDesktopMomentumScroller(domTableReference, false);
    unWatch();
});

const reverseSplits = computed(() => props.timingChannel.Splits.toReversed());

const { sortedStartList } = useTimingChannelData(toRef(props, "timingChannel"));
</script>

<style scoped lang="scss"></style>
