<template>
    <WtcChannel :channel-name="currentUnitsChannelName" @update:channel="curUnitsChan = $event">
        <template #slot__result>
            <div class="bg-brand flex flex-col justify-between p-3 text-white">
                <div class="text-2xl uppercase">{{ data.currentEventData?.Header1 }}</div>
                <div class="text-sm text-white/60">
                    {{ getCombinedValue(data.currentEventData?.Header2, data.currentEventData?.Header3, ", ") }}
                </div>
            </div>

            <WtcChannel :channel-name="curTimingChannelName">
                <template #slot__result="{ channelContent }">
                    <StkCurrentUnit :timing-channel="channelContent"></StkCurrentUnit>
                </template>
            </WtcChannel>
        </template>

        <template #slot__not-initialized>
            <FalconPlaceHolder msg="no-event"></FalconPlaceHolder>
        </template>
    </WtcChannel>
</template>
<script setup lang="ts">
import { FalconPlaceHolder, useFalconStore } from "@swisstiming/falcon-core";
import {
    getCombinedValue,
    getCurrentChannel,
    getTimingChannel,
    ICurrentUnitsChannel,
    WtcChannel
} from "@swisstiming/webtec-kit";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";

import StkCurrentUnit from "../views/StkCurrentUnit.vue";
const { config, data } = storeToRefs(useFalconStore());

const currentUnitsChannelName = computed(() => getCurrentChannel(config.value.tournamentId));
const curUnitsChan = ref<ICurrentUnitsChannel>({});

const curTimingChannelName = computed(() =>
    getTimingChannel(config.value.tournamentId, curUnitsChan.value.CurrentUnitRsc ?? curUnitsChan.value.CurrentRsc)
);
</script>

<style lang="scss"></style>
