export const isIOS = () => !!navigator.platform && /mac|ipad|iphone/.test(navigator.platform.toLowerCase());

export const getQueryParamDictionary = <T extends string>() =>
    Object.fromEntries(new URLSearchParams(window.location.search)) as {
        [key in T]: string;
    } & { [x: string]: string };

export const checkWebSocket = () => ("WebSocket" in window && !("__addTask" in WebSocket)) || "MozWebSocket" in window;

export const checkCookieSupport = () => navigator.cookieEnabled;

export const addScript = (src, callback) => {
    const s = document.createElement("script");
    s.setAttribute("src", src);
    s.onload = callback;
    document.body.appendChild(s);
};
