import { isIOS } from "./browser.utils";

export const createNativeDesktopMomentumScroller = (elem, useWebkitOverflowTouch = false) => {
    const instance = {
        drag: false,
        isInMovement: false,
        time: 0.04,
        wait: false,
        start: 1, // weird value to start with
        el: elem,
        startx: undefined,
        starty: undefined,
        diffx: undefined,
        diffy: undefined,
        animate: undefined,

        /**
         * @method _setupStyles
         */
        _setupStyles() {
            // Reset all inline-styles
            elem.removeAttribute("style");

            // add iOS-specific inline-styles
            if (isIOS()) {
                // Only recognized and added by browsers on iOS,
                if (useWebkitOverflowTouch === true) {
                    elem.style.WebkitOverflowScrolling = "touch";
                }
            }
        },
        /**
         * @method addEvent
         */
        addEvent(name, el, func, priohandling = true) {
            if (el.addEventListener) {
                el.addEventListener(name, func, priohandling);
            }
        },
        /**
         * @method cancelEvent
         */
        cancelEvent(e) {
            if (e.target && e.target.nodeName === "IMG") {
                // no idea what that actually is
                e.preventDefault();
            } else if (e.srcElement && e.srcElement.nodeName === "IMG") {
                e.returnValue = false;
            }
        },
        /**
         * @method onMouseDown
         */
        onMouseDown(e) {
            if (instance.drag === false || instance.wait === false) {
                instance.drag = true;
                instance.cancelEvent(e);
                e.stopPropagation();
                e.preventDefault();

                instance.startx = e.clientX + instance.el.scrollLeft;
                instance.starty = e.clientY + instance.el.scrollTop;
                instance.diffx = 0;
                instance.diffy = 0;
            }
        },

        onClick(e) {
            if (instance.isInMovement === true) {
                e.preventDefault();
                e.stopPropagation();
            }
        },
        onMouseMove(e) {
            if (instance.drag === true) {
                instance.diffx = instance.startx - (e.clientX + instance.el.scrollLeft);
                instance.diffy = instance.starty - (e.clientY + instance.el.scrollTop);
                instance.el.scrollLeft += instance.diffx;
                instance.el.scrollTop += instance.diffy;
                instance.isInMovement = true;
            }
        },
        /**
         * @method onMouseMove
         */
        onMouseUp(e) {
            if (instance.drag === true) {
                e.stopPropagation();
                e.preventDefault();

                instance.cancelEvent(e);
                instance.start = 1.5705; // Pi/2
                instance.drag = false;

                instance.animate = () => {
                    const step = Math.sin(instance.start);

                    if (step <= 0 || instance.drag === true) {
                        instance.diffx = 0;
                        instance.diffy = 0;
                        instance.isInMovement = false;

                        window.cancelAnimationFrame(instance.animate);
                    } else {
                        instance.el.scrollLeft += instance.diffx * step;
                        instance.el.scrollTop += instance.diffy * step;
                        instance.start -= instance.time;
                        window.requestAnimationFrame(instance.animate);
                    }
                };
                instance.animate();
            }
        },

        _registerEvents() {
            elem.addEventListener("click", instance.onClick, true);
            elem.addEventListener("mousedown", instance.onMouseDown, true);
            document.addEventListener("mouseup", instance.onMouseUp, true);
            document.addEventListener("mousemove", instance.onMouseMove, false);
        },

        destroy() {
            elem.removeEventListener("click", instance.onClick, true);
            elem.removeEventListener("mousedown", instance.onMouseDown, true);
            document.removeEventListener("mouseup", instance.onMouseUp, true);
            document.removeEventListener("mousemove", instance.onMouseMove, false);
        }
    };
    instance._setupStyles();
    instance._registerEvents();
    return instance;
};
