import { isNil, orderBy, toFinite } from "lodash-es";
import { computed, Ref } from "vue";

import { IntermediateTypes, ScheduleStatus } from "../enums/channel.enums";
import { ICompetitorDetail, IOrder } from "../interfaces/channel/commons";
import { ITimingChannel } from "../interfaces/channel/timing";
import { isNumeric } from "../utils/validation";
import { useChannel } from "./channel.composables";

export const mapIdToCompetitor = (
    targetMapList: { [x: string]: ICompetitorDetail },
    sortedList: IOrder
): ICompetitorDetail[] => {
    if (targetMapList && sortedList) {
        return orderBy(sortedList, (x) => x.ListIndex)
            .filter(({ Id }) => targetMapList[Id] !== undefined)
            .map(({ Id }) => {
                const competitor = targetMapList[Id];
                const Children = competitor.Children?.map((child) => targetMapList[child.Id] ?? child);
                return { ...competitor, Children };
            });
    }
};

export const useTimingChannelData = <T extends ITimingChannel>(timingChannel: Ref<T>) => {
    const sortedResultList = computed(() =>
        mapIdToCompetitor(timingChannel.value.CompetitorDetails, timingChannel.value.Resultlist)
    );

    const sortedStartList = computed(() =>
        mapIdToCompetitor(timingChannel.value.CompetitorDetails, timingChannel.value.Startlist)
    );

    const sortedRetiredList = computed(() =>
        mapIdToCompetitor(timingChannel.value.CompetitorDetails, timingChannel.value.Retiredlist)
    );

    const sortedLiveRankingList = computed(() =>
        mapIdToCompetitor(timingChannel.value.CompetitorDetails, timingChannel.value.LiveRanking)
    );

    const statTypes = computed((): { type: string; compType: string } => {
        return {
            type: timingChannel.value.Stats.Type,
            compType: timingChannel.value.Stats.CompType
        };
    });
    const isMixedTeam = (comp: ICompetitorDetail) => comp?.IsTeam && ["O", "X"].includes(comp?.Gender);

    const isLive = computed(() => timingChannel.value.Status === ScheduleStatus.Running);

    const officials = computed(() => timingChannel.value.Officials ?? []);

    const relevantIntermediates = computed(
        () =>
            timingChannel.value.Splits?.filter(
                (int) =>
                    int.Stats?.Type === IntermediateTypes.INTERMEDIATE ||
                    int.Stats?.Type === IntermediateTypes.FINISH ||
                    int.Id?.includes("INT")
            ) ?? []
    );

    const currentIntermediate = computed(() => {
        const currentSplitValue = timingChannel.value?.Stats?.CurSplit;

        // check if there is a relevant intermediate with the 'Id' === 'CurSplit'
        let relevantIntermediate =
            relevantIntermediates.value?.find((int) => !isNil(int.Id) && int.Id === currentSplitValue) ?? null;

        // if there is no relevant intermediate with the 'Id' === 'CurSplit', check against the prop 'Number' instead
        // for this 'CurSplit' needs to be transformed into a number
        if (isNil(relevantIntermediate)) {
            let relevantIntermediateNumber = null;
            if (isNumeric(currentSplitValue)) {
                relevantIntermediateNumber = toFinite(currentSplitValue);
            } else if (currentSplitValue === "F") {
                relevantIntermediateNumber = relevantIntermediates.value?.length;
            }
            relevantIntermediate =
                relevantIntermediates.value?.find(
                    (int) => !isNil(int.Number) && int.Number === relevantIntermediateNumber
                ) ?? null;
        }

        // return the current intermediate item
        return relevantIntermediate;
    });

    const getLatestRelevantIntermediates = (amount = 1, withFinishInt = false) => {
        // get index of the 'currentIntermediate'
        const currentIndex = relevantIntermediates.value.indexOf(currentIntermediate.value);

        // check if 'currentIntermediate' is a finish-int
        const isFinishInt =
            ["INT_F", "F"].includes(currentIntermediate.value?.Id) ||
            currentIntermediate.value?.Stats?.Type === IntermediateTypes.FINISH;

        const intermediates = relevantIntermediates.value?.filter((_, index) => index <= currentIndex);

        if (!withFinishInt && isFinishInt) {
            // return an array with the last x intermediates from the relevant intermediates (EXCLUDES finish-int)
            return intermediates?.slice(0, -1)?.slice(-amount) ?? [];
        }

        // return an array with the last x intermediates from the relevant intermediates (INCLUDES finish-int)
        return intermediates?.slice(-amount) ?? [];
    };

    const isPara = computed(() => timingChannel.value.Stats?.IsPara?.toLocaleLowerCase() === "true");

    const hasParaClasses = computed(
        () => isPara.value && sortedResultList.value.some((comp) => !isNil(comp.Details?.Class))
    );
    const hasParaGuide = (comp: ICompetitorDetail) => isPara.value && comp?.Details?.Guide_1;

    const currentCompetitor = computed(
        () => timingChannel.value.CompetitorDetails[timingChannel.value.CurrentCompetitor?.Id]
    );

    const performingCompetitorClasses = (comp: ICompetitorDetail) => {
        return {
            ["xg--is-performing"]: comp.Id === currentCompetitor.value?.Id
        };
    };

    return {
        sortedResultList,
        sortedStartList,
        sortedRetiredList,
        sortedLiveRankingList,
        statTypes,
        isMixedTeam,
        isLive,
        currentIntermediate,
        getLatestRelevantIntermediates,
        rawData: timingChannel,
        isPara,
        hasParaClasses,
        hasParaGuide,
        officials,
        currentCompetitor,
        performingCompetitorClasses
    };
};

export const useTimingChannel = (timingChannelName: Ref<string>) =>
    useTimingChannelData(useChannel<ITimingChannel>(timingChannelName).content);
