import "./styles/styles.scss";

import { falconPlugin } from "@swisstiming/falcon-core";
import { SportCodes } from "@swisstiming/webtec-kit";
import { createApp } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";

import App from "./App.vue";
import StkApp from "./views/StkApp.vue";

const router = createRouter({
    history: createWebHashHistory(),
    routes: [{ path: "/", component: StkApp }]
});

createApp(App)
    .use(falconPlugin, {
        title: "Short Track Web Results",
        router,
        storeOptions: {
            sportCode: SportCodes.SHORT_TRACK,
            initOptions: {
                withCurrentEvent: true,
                withCisConfig: false,
                withSeason: false
            },
            iframeIntegration: {
                required: true,
                domains: [
                    "sports.swisstiming.com", // uat
                    "app-dev-isu-stk-archive-westeurope.azurewebsites.net",
                    "www.liveresults.swisstiming.com", // prod
                    "shorttrack-archive.swisstiming.com", // prod
                    "app-prod-isu-stk-archive-westeurope.azurewebsites.net", // origin PROD
                    "shorttrack-archive.azureedge.net", // CDN PROD
                    "shorttrack.swisstiming.com" // public PROD
                ]
            }
        }
    })
    .mount("#app");
