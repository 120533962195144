<template>
    <component :is="tag" v-if="useFirstnameLastname" class="wtc-name">
        <span class="name-1" :class="pt.name1">{{ firstName }}</span>
        <span class="name-2" :class="pt.name2">{{ lastName }}</span>
    </component>
    <component :is="tag" v-else class="wtc-name">
        <span class="name-1" :class="pt.name1">{{ lastName }}</span>
        <template v-if="hasDelimiter">, </template>
        <span class="name-2" :class="pt.name2">{{ firstName }}</span>
    </component>
</template>
<script setup lang="ts">
import { ICompetitorNames } from "@swisstiming/webtec-kit";
import { computed } from "vue";

const props = withDefaults(
    defineProps<{
        competitorName?: ICompetitorNames;
        useFirstnameLastname?: boolean;
        useShortName?: boolean;
        tag?: string;
        pt?: { name1?: string; name2?: string };
    }>(),
    {
        competitorName: (): ICompetitorNames => {
            return {};
        },
        useFirstnameLastname: false,
        useShortName: false,
        tag: "div",
        pt: () => ({ name1: undefined, name2: undefined })
    }
);
const firstName = computed(() =>
    props.useShortName ? props.competitorName.FirstNameShort : props.competitorName.FirstName
);

const lastName = computed(() => props.competitorName.Name);

const hasDelimiter = computed(() => props.competitorName.Name && firstName.value);
</script>
