<template>
    <div class="falcon-frame w-full">
        <slot v-if="config.iframeIntegration.required && !config.iframeIntegration.authorized" name="unauthorized">
            <FalconPlaceHolder msg="no-access" />
        </slot>
        <template v-else>
            <template v-if="firstConnectEstablished">
                <slot name="header"></slot>
                <router-view />
                <slot name="footer"></slot>
            </template>
            <slot v-else name="loading">
                <WtcLoading />
            </slot>
        </template>
    </div>
</template>
<script setup lang="ts">
import { useFalconStore } from "@swisstiming/falcon-core";
import { WtcLoading } from "@swisstiming/webtec-kit";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";

import FalconPlaceHolder from "./FalconPlaceHolder.vue";

const { config, isReady } = storeToRefs(useFalconStore());

const firstConnectEstablished = ref(false);

const unWatch = watch(isReady, (newVal, prevVal) => {
    if (prevVal === false && newVal === true) {
        firstConnectEstablished.value = true;
        unWatch();
    }
});
</script>

<style scoped lang="scss"></style>
